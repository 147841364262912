import React, { useState, useEffect } from "react";
import { Icon,
  ZWrapper,
  Button } from "../../ui-components";
import {
  FullScreenShadow,
  FullScreen,
  FullScreenWrap,
  FullScreenContainer,
  Container,
  IconButton,
  LogoPlaceholder,
  Logo,
} from "./crowdfunding.styled";
import crowfunding_logo from "../../assets/img/crowdcube-logo.svg"

const CROWDFUNDING_URL = "https://www.crowdcube.com/companies/betriton/pitches/bdpD5b";

export const CrowdFundingPopup = () => {
  const [seen, setSeen] = useState(true)
  const [visible, setVisible] = useState(false)

  useEffect(() => {
    if (localStorage.getItem('crowdfunding_seen')) {
      hidePopup()
      setSeen(true)
    } else {
      showPopup()
      setSeen(false)
    }
  }, [])

  const showPopup = () => {
    if (document.getElementById("layout")) {
      document.getElementById("layout").style.position = 'fixed';
    }
    setVisible(true)
  }

  const hidePopup = () => {
    if (document.getElementById("layout")) {
      document.getElementById("layout").style.position = 'relative';
    }
    setVisible(false)
    setSeen(true)
  }

  const handleOpen = () => {
    showPopup();
  }
  const handleClose = () => {
    localStorage.setItem('crowdfunding_seen', true);
    hidePopup();
  }

  const size = 24;

  return (
    <>
    {visible && 
    <FullScreenShadow>
      <FullScreen>
        <FullScreenWrap>
          <FullScreenContainer>
            <IconButton close type="button" onClick={handleClose}>
              <Icon
                name="close-outline"
                width={size}
                height={size}
                noFill
                  />
                </IconButton>
                <ZWrapper
                  size="small"
                  color="white"
                  shadow
                >
                  <Container>
                    <LogoPlaceholder>
                      <a href={CROWDFUNDING_URL}><Logo src={crowfunding_logo} alt="Crowdcube" /></a>
                    </LogoPlaceholder>
                    <p>We're crowdfunding!<br />
                      This is a limited opportunity to own part of BeTriton.
                      Capital at Risk.</p>
                    <Button
                      type="link"
                      size="large"
                      to={CROWDFUNDING_URL}
                      color="thirdiary"
                    >
                      Invest
                    </Button>
                  </Container>
                </ZWrapper>
              </FullScreenContainer>
        </FullScreenWrap>
      </FullScreen>
    </FullScreenShadow>
    }
      <div>
      <Button
        type="button"
        size="large"
        color="thirdiary"
        onClick={() => { handleOpen() }}
      >
        Invest
      </Button>
      </div>
    </>
  )
}

export default CrowdFundingPopup;
